import "../styles/Terms.css";

function Terms() {
  return (
    <div className="jazz_discount_outer">
      <h2>Terms & Conditions</h2>
      <div style={{ background: "rgba(0,0,0,0.8)", padding: "20px" }}>
        <div>
          <p>
            This Agreement sets forth the legally binding terms and conditions
            governing the acquisition and use by you, the “Client”, of Smart
            Club (the “Service”).
          </p>
          <p>
            <br />
            <strong>1. GENERAL CONDITIONS</strong>
          </p>
          <p>
            1.1 Smart Club is an auto-renewing content subscription service that
            delivers electronic content to subscribers in the form of Android
            apps upon payment of a daily fee.&nbsp;
            <br />
            1.1.1 The download and use of such content is unrestrained, there
            being no limit to the quantity of apps that a paying client is
            allowed to download, the sole restriction being the storage capacity
            of the client's mobile equipment.
          </p>
          <p>
            1.2 All content downloaded from Smart Club does not belong to
            Telenor and is made available by third-party developers. For this
            reason, Telenor assumes no responsibility for the apps, their
            integrity, functionality, or for any permissions granted by the
            client while accessing the content.
          </p>
          <p>
            1.3 The client is hereby made aware that, for the download and use
            of the apps in Smart Club, a mobile device is required, which must
            be compatible with Android technology, with Android version 2.3 or
            higher installed.&nbsp;
            <br />
            1.3.1 The client is hereby made aware that the use of Smart Club and
            its content requires an Internet connection (wifi, GPRS, EDGE, 3G,
            4G or other). Offline usage will be restricted to 5 (five) offline
            app openings.
          </p>
          <p>
            1.4 The client hereby acknowledges and agrees that by subscribing to
            the service all charges and fees are due, regardless of the client's
            mobile equipment meeting any compatibility criteria upon
            subscription. No refunds will be issued by Telenor based on the
            client's inaptitude to access the service due to lack of compatible
            mobile equipment.
          </p>
          <p>
            1.5 Internet access and any data traffic associated with the
            download and use of the service will be charged as appropriate by
            the client’s respective mobile network provider. Telenor may at its
            sole discretion exempt the user from data traffic charges associated
            with the download of content, on a temporary basis, and the client
            acknowledges that this exemption may be terminated at any time.
          </p>
          <p>
            1.6 To subscribe to the service the client must either be of legal
            age or be assisted as appropriate by his/her parents or legal
            guardians.&nbsp;
            <br />
            1.6.1 Whenever the service is accessed or purchased by minors, the
            responsibility for the access will lie with the bill payer for the
            respective mobile network provider, who will be assumed to have
            given permission to the minor to use of the service. Telenor will
            not be held accountable for improper or unintended use of the apps
            or for the unintended acquisition of products. The legal
            guardian/contracting party is the sole responsible for the care and
            keeping of the mobile equipment required to use the service.
          </p>
          <p>
            1.7 Telenor will not be held accountable for the inaptitude of the
            client in connecting to the Internet, employing hardware equipment
            or software in the download and use of apps, or for the availability
            of any third-party or Internet links to the service. The client
            acknowledges that Telenor gives no warranty or representation that
            the service will meet the client’s requirements or any objectives in
            particular.
          </p>
          <p>
            1.8 Telenor will not be held accountable for any form of advertising
            and/or for the content of banner ads, pop-up windows and any other
            advertising formats shown on websites and third-party apps made
            available through the service and its portals. The responsibility
            for such content lies solely on the advertiser or their legal
            representatives.
          </p>
          <p>
            1.9 The client acknowledges that given the nature of such services,
            Telenor cannot guarantee that the service will be uninterrupted or
            error-free.
          </p>
          <p>
            1.10 Telenor assumes no responsibility for any damage or loss
            incurred as a result of the client’s access to fake websites and
            portals that mimic the service’s pages and which are not governed by
            the present terms and conditions of use.&nbsp;
            <br />
            1.11 The client acknowledges that under no circumstances will
            Telenor be held accountable for any damage or loss incurred as a
            result of:&nbsp;
            <br />
            (i) the client’s use or inability to use the service;&nbsp;
            <br />
            (ii) the sharing of personal information to social networks in
            association with the service;&nbsp;
            <br />
            (iii) unauthorized access to user data or data transmissions, or any
            adulterations of personal data as a result of unauthorized
            access;&nbsp;
            <br />
            (iv) acts and behavior of third-parties upon the service;&nbsp;
            <br />
            (v) force majeure or fortuitous event or any acts performed by the
            client.
          </p>
          <p>
            1.12 Telenor will not reimburse any amounts eventually charged to
            the client under the following claims:&nbsp;
            <br />
            (i) incompatibility between hardware and software elements employed
            by the client and the service;&nbsp;
            <br />
            (ii) the client’s mobile equipment not meeting the minimum
            requirements to run the apps;&nbsp;
            <br />
            (iii) misexpectations or errors attributable to the client;&nbsp;
            <br />
            (iv) the lack of observation by the client of any policies mentioned
            in the present terms and of any instructions available in the
            service’s portals and websites;&nbsp;
            <br />
            (v) acts of bad faith;&nbsp;
            <br />
            (vi) client’s withdrawal after the service has been granted.
          </p>
          <p>
            1.13 Even where the client is apt to use the service it is his or
            her responsibility to act in conformity with the principles and in
            observance of the rules of the service, expressly as presented,
            without warranties or other conditions not explicitly stated in the
            present terms and conditions of use. The content made available
            through the service is determined at Telenor’s discretion. All
            content is the sole responsibility of the content owner in regard to
            quality, precision, fidelity, reliability, information, opinion,
            declaration, warranties and advertising of any sort.
          </p>
          <p>
            1.14 Telenor will not be held accountable for any changes or
            withdrawals of apps or of content providers from the service and for
            any outcomes of these changes. The client acknowledges and agrees
            that such changes and withdrawals are inherent to the service.&nbsp;
            <br />
            1.14.1 Telenor will apply reasonable efforts in obtaining similar
            apps to the ones withdrawn from the service.
          </p>
          <p>
            <br />
            <strong>2. BILLING</strong>
          </p>
          <p>
            2.1 Clients will be charged upto in the amount of Rs.5 for every day
            for as long as they remain subscribers, regardless of their use of
            the apps they are entitled to.
          </p>
          <p>
            2.2 Data usage charges as per standard package plan. If the
            subscriber's account is successfully charged the subscriber is
            provided access to the store to download content for 1 day from the
            payment date.&nbsp;
            <br />
            2.2.1 Clients who remain with zero prepaid credit balance for a long
            period while they still have access to the service, in accordance
            with the present terms and conditions of use, may be charged in full
            or in part after topping-up their credit balance, consonant with the
            number of weeks in which they remained active in the service.
          </p>
          <p>
            2.3 Additional charges may apply in accordance with applicable law.
          </p>
          <p>
            2.4 Prices are subject to change without prior notice and may vary
            according to the purchasing channel. The client acknowledges that
            any prices that differ from the ones described in the present terms
            and conditions of use will be informed at the time of purchase.
          </p>
          <p>
            <br />
            <strong>3. PURCHASING CHANNELS</strong>
          </p>
          <p>
            3.1 The client may subscribe to the service through the following
            channels: Website;
          </p>
          <p>
            <br />
            <strong>4. UNSUBSCRIPTION CHANNELS</strong>
          </p>
          <p>
            4.1 You can de-activate your account&nbsp;
            <br />
            1. Go to http://smart.appsclub.com from your Mobile phone
            browser&nbsp;
            <br />
            2. Click on "Account" at the bottom of the page.&nbsp;
            <br />
            3. Click "Unsubscribe".
          </p>
          <p>
            4.1.1 Upon cancellation, clients lose access to all previously
            installed apps from the service.
          </p>
          <p>
            4.2 No termination fee is due upon cancellation. Regular
            subscription fees that are eventually due at the time of termination
            may still be charged to the client.
          </p>
          <p>
            4.3 Until the client asks to be subscribed from the service through
            any of the above mentioned channels, the subscription will be
            automatically and continuously renewed.
          </p>
          <p>
            4.4 Telenor reserves the right to terminate the service without
            prior notice, at its sole discretion, in the event of misuse or
            fraudulent use of the service by the client, in which case no
            entitlement to compensation whatsoever exists.
          </p>
          <p>
            <br />
            <strong>5. INTELLECTUAL PROPERTY</strong>
          </p>
          <p>
            5.1 All content is protected by intellectual property rights and
            other laws including but not limited to the Brazilian Civil Rights
            Code and Property Rights Law and is marketed through licensing
            agreements with their rightful owners.
          </p>
          <p>
            5.2 By accessing the service and any mobile app from Telenor and its
            partners the client agrees to observe and abide by all intellectual
            and industrial property rights, including copyrights or trademarks
            belonging to Telenor and to any third party content licensor to
            Telenor that is made available through the service. As a user you do
            not acquire any ownership rights over the content, including names,
            titles, words, phrases, logos, patents and trademarks, literary and
            other artistic works, that is or once was made available through the
            service.
          </p>
          <p>
            5.3 You are granted a non-exclusive, non-transferable, revocable
            license to access and use the content strictly in accordance with
            the present terms and conditions of use. You will not modify,
            publish, transmit, reverse engineer, participate in the transfer or
            sale, create derivative works, or in any way exploit any of the
            content, in whole or in part, found on the service.
          </p>
          <p>
            5.4 Your use of the service does not entitle you to make any
            unauthorized use of any protected content, and in particular you
            will not delete or alter any proprietary rights or attribution
            notices in any content. You will use the protected content solely
            for your personal use, and will make no other use of the content
            without the express written permission of Telenor and the copyright
            owner. You agree that you do not acquire any ownership rights in any
            protected content. We do not grant you any licenses, express or
            implied, to the intellectual property of Telenor or our licensors
            except as expressly authorized by the present terms and conditions
            of use.
          </p>
          <p>
            5.5 Telenor is not responsible or liable for any disputes or
            disagreements between you and any third-party in connection with
            your use of the service. You assume all risk associated with dealing
            with these third parties, and you release Telenor of all claims,
            demands, and damages in connection with these disputes.
          </p>
          <p>
            <br />
            <strong>6. FINAL PROVISIONS</strong>
          </p>
          <p>
            6.1 Telenor reserves the right to modify or change, at any given
            time and without any previous notice, the current terms and
            conditions of use.
          </p>
          <p>
            6.2 Any omission or tolerance of Telenor to demand strict compliance
            with the obligations or exercise any right arising out of this
            terms, shall not constitute novation or waiver nor affect their
            right to exercise it at any time.
          </p>
          <p>
            6.3 If any provision of this instrument will be considered null,
            void, illegal or unenforceable by any court, such decision shall not
            affect the validity of the remaining provisions, which shall remain
            in force and effect.
          </p>
          <p>
            6.4 Telenor will have the final say on any issues not addressed in
            this agreement.
          </p>
          <p>
            6.5 Each of the services provided by Telenor through its websites
            and portals are subject to their own terms and conditions. By
            accessing or otherwise using the service the client acknowledges
            that it has read carefully all applicable terms and conditions,
            which form an integral part of the present terms.
          </p>
          <b>
            <a href="https://www.telenor.com.pk/privacy-noticе/">
              Telenor Privacy Policy
            </a>
          </b>
        </div>
      </div>
    </div>
  );
}

export default Terms;
