import React from "react";
import "../styles/Landing.css";
import Logo from "../Assets/imgs/logo.webp";
import pageContent from "../Assets/imgs/content-img.webp";
import dayFree from "../Assets/imgs/1day-free.webp";
import kidImage from "../Assets/imgs/main-img.webp";

type Props = {
  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  showPlay: boolean;
  confirmLoader: boolean;
  phoneNo: string;
  onHandlePlay: () => void;
};

const Landing = (props: Props) => {
  return (
    <>
      <div className="main-sub">
        <div className="sub-content">
          <img
            className="logo"
            src={Logo}
            width="154"
            height="33"
            alt="logo image"
          />
          <img
            className="content"
            src={pageContent}
            width="395"
            height="150"
            alt="main image"
          />

          {props.showError && <p className="error">{props.errorMsg}</p>}
          {props.phoneNo && (
            <>
              {props.showPlay ? (
                <>
                  {props.confirmLoader ? (
                    <button className="disable-btn btn-play">Confirm</button>
                  ) : (
                    <button
                      className="btn-play"
                      onClick={() => props.onSubscribePressed()}
                    >
                      Confirm
                    </button>
                  )}
                </>
              ) : (
                <button className="btn-play" onClick={props.onHandlePlay}>
                  PLAY NOW
                </button>
              )}
            </>
          )}
          <p className="blue">Rs. 40/ Week! including tax</p>

          <div className="footer">
            <img src={kidImage} width="375" height="409" alt="footer image" />
            <div className="footer-last">
              <p className="text-last">
                I agree for daily recurrent charges deduction from mobile
                account{" "}
              </p>
              <a href="/terms" className="text-last">
                Terms & Condition
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
